import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";

const Wrapper = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  padding-left: 50px;
  padding-right: 50px;
`;

const Container = styled.div`
  margin: 0 auto;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  max-height: 1080px;
  object-fit: cover;
`;

const ContentContainer = styled.div`
  display: flex;
  margin-top: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LeftColumn = styled.div`
  flex: 1;
  padding-right: 20px;
  text-align: left;
`;

const RightColumn = styled.div`
  flex: 1;
  padding-left: 20px;
  text-align: left;
`;

const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 10px;
`;

const Subtitle = styled.p`
  font-size: 16px;
`;

const Text = styled.p`
  margin-bottom: 10px;
`;

const ReadMore = styled.a`
  color: blue;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const JournalEntry = () => {
  const [entry, setEntry] = useState(null);

  useEffect(() => {
    const fetchEntry = async () => {
      try {
        const response = await axios.get(
          "https://strapi-production-7b58.up.railway.app/api/journal-entry?populate=image"
        );
        setEntry(response.data.data.attributes);
      } catch (error) {
        console.error("Error fetching journal entry:", error);
      }
    };

    fetchEntry();
  }, []);

  if (!entry) return <div>Loading...</div>;

  const imageUrl =
    entry.image?.data?.attributes?.url ||
    "https://via.placeholder.com/1920x1080";

  return (
    <Wrapper>
      <Container>
        <Image src={imageUrl} alt={entry.title || "Journal Entry Image"} />
        <ContentContainer>
          <LeftColumn>
            <Title>THE JOURNAL</Title>
            <Subtitle>{entry.title}</Subtitle>
          </LeftColumn>
          <RightColumn>
            <Text>{entry.subtitle}</Text>
            <ReadMore href="#">Read more</ReadMore>
          </RightColumn>
        </ContentContainer>
      </Container>
    </Wrapper>
  );
};

export default JournalEntry;
