// src/services/strapiService.js
import axios from "axios";

const API_URL = "https://strapi-production-7b58.up.railway.app/api";

export const fetchHeroImage = async () => {
  try {
    const response = await axios.get(`${API_URL}/hero-image?populate=*`); // Populate the image field
    return response.data.data; // Strapi returns the content under data.data
  } catch (error) {
    console.error("Error fetching hero image from Strapi:", error);
    throw error;
  }
};

//ITEMS
//*****************************************************************************************************************
export const fetchClothingItems = async () => {
  const response = await axios.get(
    "https://strapi-production-7b58.up.railway.app/api/clothing-items?populate=*"
  );
  return response.data.data; // Return the data directly
};

//SCROLLER
//*****************************************************************************************************************
export const fetchScrollerOne = async () => {
  try {
    const response = await axios.get(
      `${API_URL}/scroller-one?populate=clothing_items`
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching scroller-one data:", error);
    return null;
  }
};
