import React, { useRef } from "react";

const Card = ({ image, name, brand, price }) => (
  <div className="card">
    <div className="cardImageContainer">
      <img
        src={image}
        style={{ objectFit: "contain", height: "90px" }}
        alt="Card"
      />
    </div>
    <div className="cardTextContainer">
      <p>{name}</p>
      <p style={{ fontWeight: "600" }}>{brand}</p>
      <p>{price}</p>
    </div>
  </div>
);

const HorizontalScroller = ({ cards }) => {
  const scrollContainerRef = useRef(null);

  const scrollLeft = () => {
    scrollContainerRef.current.scrollBy({
      left: -200,
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    scrollContainerRef.current.scrollBy({
      left: 200,
      behavior: "smooth",
    });
  };

  return (
    <div className="horizontal-scroll">
      <button className="scroll-button left" onClick={scrollLeft}>
        &lsaquo;
      </button>
      <div className="scroll-container" ref={scrollContainerRef}>
        {cards.map((card, index) => (
          <Card
            key={index}
            image={card.image}
            name={card.name}
            brand={card.brand}
            price={card.price}
          />
        ))}
      </div>
      <button className="scroll-button right" onClick={scrollRight}>
        &rsaquo;
      </button>
    </div>
  );
};

export default HorizontalScroller;
