import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Bar = styled.nav`
  height: 53px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid lightgrey; /* Adds the 1px black line */
  @media (max-width: 768px) {
    visibility: hidden;
    height: 0px;
  }
`;

const MenuList = styled.ul`
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
`;

const MenuItem = styled.li`
  margin: 0 10px; // This creates 10px spacing between items (5px on each side)

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

// Styled Link for React Router
const MenuLink = styled(Link)`
  text-decoration: none;
  color: black;
  font-size: 14px;
  &:hover {
    text-decoration: underline;
  }
`;

const menuItems = [
  "Home",
  "Men",
  "Women",
  "Designers",
  "Clothing",
  "Shoes",
  "Bags",
  "Accessories",
  "Watches",
  "Sport",
  "Home & Gifts",
];

const MenuBar = () => {
  return (
    <Bar>
      <MenuList>
        {menuItems.map((item, index) => {
          let to;
          if (item === "Men") {
            to = "/items-men";
          } else if (item === "Women") {
            to = "/items-women";
          } else {
            to = "/";
          }
          return (
            <MenuItem key={index}>
              <MenuLink to={to}>{item}</MenuLink>
            </MenuItem>
          );
        })}
      </MenuList>
    </Bar>
  );
};

export default MenuBar;
