import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import HeaderBar from "../components/header-bar";
import MenuBar from "../components/menu-bar";
import ItemCard from "../components/item-card";
import FooterNewsletter from "../components/footer-newsletter";
import Footer from "../components/footer";
import { useItemsData } from "../providers/DataContext";
import PageIndicator from "../components/page-indicator";

const Container = styled.div`
  display: flex;
  padding: 20px;
  max-width: 1600px;
  margin: 50px auto;
`;

const LeftMenu = styled.div`
  width: 400px;
  display: none;

  @media (min-width: 1000px) {
    display: block;
  }
`;

const RightContent = styled.div`
  flex-grow: 1;
`;

const ItemsGrid = styled.div`
  display: grid;
  gap: 20px;

  @media (min-width: 1000px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 999px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const ItemsPage = ({ gender }) => {
  const { state } = useLocation();
  const items = useItemsData();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    // Set initial search term from state, if any
    if (state?.searchTerm) {
      setSearchTerm(state.searchTerm);
    }

    // Clear search term when component unmounts or route changes
    return () => {
      setSearchTerm("");
    };
  }, [state]);

  useEffect(() => {
    // Filter items based on the search term first
    const searchedItems = searchTerm
      ? items.filter((item) =>
          Object.values(item).some(
            (value) =>
              value != null &&
              value.toString().toLowerCase().includes(searchTerm.toLowerCase())
          )
        )
      : items;

    // Apply gender filtering
    const filtered = searchedItems.filter((item) => {
      // Include items with null gender or items that match the selected gender
      return gender === null || item.gender === gender || item.gender === null;
    });

    setFilteredItems(filtered);
  }, [items, searchTerm, gender]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemClick = (item) => {
    navigate(`/item-details/${item.id}`);
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
    setCurrentPage(1); // Reset to first page when searching
  };

  return (
    <>
      <HeaderBar setSearchTerm={handleSearch} />
      <MenuBar />
      <Container>
        <LeftMenu>
          <p>Menu Placeholder</p>
        </LeftMenu>
        <RightContent>
          <ItemsGrid>
            {currentItems.map((item) => (
              <ItemCard
                key={item.id}
                id={item.id}
                images={item.images}
                name={item.brand}
                subtitle={item.title}
                price={`$${item.price}`}
                description={item.description}
                onClick={() => handleItemClick(item)}
              />
            ))}
          </ItemsGrid>
        </RightContent>
      </Container>
      <PageIndicator
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
      <FooterNewsletter />
      <Footer />
    </>
  );
};

export default ItemsPage;
