import React, { useState } from "react";
import styled from "styled-components";
import { FaSearch } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import SearchBarOverlay from "./search-bar-overlay";
import OmiLogo from "../assets/logos/omi_seattle_logo_black.png";

const Bar = styled.div`
  height: 80px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 8px;
    background-color: black;
  }
`;

const Logo = styled.img`
  height: 40px;
`;

const SearchButton = styled.button`
  display: flex;
  align-items: center;
  background-color: white;
  border: none;
  box-shadow: none;
  padding: 10px 15px;
  cursor: pointer;
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
`;

const SearchIcon = styled(FaSearch)`
  margin-right: 8px;
  color: #333;
`;

const SearchText = styled.span`
  font-size: 14px;
  color: #333;
`;

const HeaderBar = ({ setSearchTerm }) => {
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleSearch = (searchTerm) => {
    if (location.pathname !== "/items") {
      // Navigate to the items page first if not already there
      navigate("/items", { state: { searchTerm } });
    } else {
      // Perform the search directly if already on the items page
      setSearchTerm(searchTerm);
    }
    setIsSearchVisible(false); // Close the search bar after the search is initiated
  };

  return (
    <>
      <Bar>
        <Logo src={OmiLogo} alt="Omi Logo" />
        <SearchButton onClick={() => setIsSearchVisible(true)}>
          <SearchIcon />
          <SearchText>Search</SearchText>
        </SearchButton>
      </Bar>

      {isSearchVisible && (
        <SearchBarOverlay
          onClose={() => setIsSearchVisible(false)}
          onSearch={handleSearch} // Pass the search handling function
        />
      )}
    </>
  );
};

export default HeaderBar;
