import "react-chat-elements/dist/main.css";
import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import MessageItem from "./chat/message";
import HorizontalScroll from "./chat/horizontal-scroller";
import omiLogo from "../assets/logos/omi_seattle_logo_black.png";
import omiLogoWhite from "../assets/logos/omi_logo_white.png";
import SendIcon from "../assets/chat/send.png";
import AddImageIcon from "../assets/chat/add.png";

// Styled-components for ChatComponent
const ChatContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 9999;
`;

const ChatButton = styled.button`
  width: 60px;
  height: 60px;
  background-color: black !important;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: right 0.3s;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25);
`;

const ChatIcon = styled.img`
  width: 70px;
  height: 30px;
`;

const ChatWindow = styled.div`
  width: 350px;
  height: 600px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 999;
  padding: 20px;
  box-sizing: border-box;
  position: fixed;
  bottom: 20px;
  right: 100px;
`;

const OmiLogoContainer = styled.div`
  text-align: left;
  padding-left: 10px;
`;

const OmiLogo = styled.img`
  height: 25px;
  cursor: pointer;
`;

const Hr = styled.hr`
  color: grey;
  border: none;
  height: 1px;
  margin: 0;
`;

const MessageList = styled.div`
  height: calc(110% - 165px);
  overflow-y: auto;
  margin-top: 5px;
  margin-bottom: 10px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ImageMessage = styled.img`
  max-width: 200px;
  max-height: 200px;
  object-fit: contain;
  border-radius: 10px;
  position: relative;
  left: 30px;
`;

const InputContainer = styled.div`
  display: flex;
  position: relative;
`;

const textareaStyle = {
  resize: "none",
  overflow: "hidden",
  boxSizing: "border-box",
  height: "auto",
  lineHeight: "1.5em",
  backgroundColor: "white",
  width: "100%",
  border: "none",
  borderRadius: "10px",
  height: "45px",
  padding: "0.8em", // Add some padding for better centering
  paddingLeft: "40px",
  paddingRight: "40px" /* avoid text under icon */,
  maxHeight: "calc(1.5em * 2)", // Maximum height for two lines
  whiteSpace: "pre-wrap", // Preserve whitespace and allow line breaks
  maxHeight: "calc(1.5em * 2)", // Maximum height for two lines
  outline: "none",
  fontFamily: " Arial, sans-serif",
};

const UploadButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
`;

const UploadIcon = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  transform: translate(-1250%, -50%);
`;

const SendButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
`;

const SendIconImage = styled.img`
  width: 30px;
  height: 30px;
  position: absolute;
  transform: translate(-120%, -50%);
`;

const ChatComponent = () => {
  const [showChat, setShowChat] = useState(false);
  const [messages, setMessages] = useState([]);
  const [selectedCardSet, setSelectedCardSet] = useState([]);
  const inputRef = useRef(null);
  const messageListRef = useRef(null);
  const storedMessages = JSON.parse(localStorage.getItem("chatMessages")) || [];
  var [userMessageCount, setUserMessageCount] = useState(1);

  useEffect(() => {
    if (storedMessages.length === 0) {
      const timer = setTimeout(() => {
        if (!showChat) {
          toggleChat();
        }
        if (messages.length === 0) {
          sendOmiMessage(
            "Hey there! Please let me know if there is anything I can help you with."
          );
        }
      }, 2000);

      return () => clearTimeout(timer);
    } else {
      setMessages(storedMessages);
    }
  }, []);

  const toggleChat = () => {
    setShowChat(!showChat);
  };

  const clearMessages = () => {
    localStorage.removeItem("chatMessages");
    setMessages([]);
  };

  const sendOmiMessage = (msg) => {
    const newMessage = {
      type: "text",
      position: "right",
      text: msg,
      date: new Date(),
    };

    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages, newMessage];
      localStorage.setItem("chatMessages", JSON.stringify(updatedMessages));
      return updatedMessages;
    });
  };

  const sendMessageToAPI = async (message) => {
    const url = "https://o8i67nwtz3.execute-api.us-east-1.amazonaws.com/prod";
    const data = {
      body: {
        prompt: message,
      },
    };

    try {
      const response = await fetch(url, {
        method: "PUT", // Send as a PUT request
        //mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "your-api-key-here", // Replace with your actual API key
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Error sending message to API:", error);
      return null;
    }
  };

  const sendMessage = async () => {
    const message = inputRef.current.value.trim();
    if (message !== "") {
      // Add user message to the chat
      const userMessage = {
        type: "text",
        position: "left",
        text: message,
        date: new Date(),
      };
      setMessages((prevMessages) => [...prevMessages, userMessage]);
      localStorage.setItem(
        "chatMessages",
        JSON.stringify([...messages, userMessage])
      );
      inputRef.current.value = "";

      // Send user message to the API
      const apiResponse = await sendMessageToAPI(message);

      // Check if the API responded with a message
      if (apiResponse && apiResponse.body) {
        const apiMessage = {
          type: "text",
          position: "right",
          text: apiResponse.body, // Assuming the response contains a body with the API's reply
          date: new Date(),
        };
        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages, apiMessage];
          localStorage.setItem("chatMessages", JSON.stringify(updatedMessages));
          return updatedMessages;
        });
      }
    }

    setUserMessageCount(userMessageCount + 1);
  };

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
    if (inputRef.current !== null) {
      inputRef.current.focus();
    }
  }, [messages]);

  return (
    <ChatContainer>
      <ChatButton onClick={toggleChat}>
        <ChatIcon src={omiLogoWhite} alt="Chat Icon" />
      </ChatButton>
      {showChat && (
        <ChatWindow>
          <OmiLogoContainer>
            <OmiLogo
              src={omiLogo}
              alt="Stylist Title"
              onClick={clearMessages}
            />
          </OmiLogoContainer>
          <Hr />
          <MessageList ref={messageListRef}>
            {messages.map((message, index) =>
              message.type === "collection" ? (
                <HorizontalScroll key={index} cards={selectedCardSet} />
              ) : message.type === "image" ? (
                <ImageMessage
                  key={index}
                  src={message.image}
                  alt="Sent Image"
                />
              ) : (
                <MessageItem key={index} message={message} />
              )
            )}
          </MessageList>
          <InputContainer>
            <textarea
              ref={inputRef}
              placeholder="What are you looking for today..."
              style={textareaStyle}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  e.preventDefault();
                  sendMessage();
                }
              }}
            />
            <SendButton onClick={sendMessage}>
              <SendIconImage src={SendIcon} alt="Send message icon" />
            </SendButton>
          </InputContainer>
        </ChatWindow>
      )}
    </ChatContainer>
  );
};

export default ChatComponent;
