import React from "react";
import styled from "styled-components";

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 60px;
  background-color: #f5f5f5;
  height: 250px;

  @media (max-width: 768px) {
    height: 400px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1500px;
  width: 100%;
  padding: 0 50px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex-direction: column; /* Stack the columns vertically on mobile screens */
    padding: 0 20px; /* Adjust padding for smaller screens */
  }
`;

const LeftSection = styled.div`
  flex: 1;
  max-width: 600px;
  text-align: left;
  margin-bottom: 20px; /* Add margin on mobile to space out the sections */
  margin-right: 50px;
`;

const RightSection = styled.div`
  flex: 1;
  max-width: 400px;
  text-align: left;
`;

const Title = styled.h4`
  font-weight: bold;
  margin-bottom: 10px;
`;

const Text = styled.p`
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 1.5;
`;

const Form = styled.form`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  flex: 1;
  margin-right: 10px;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #f5f5f5;
  color: black;
  border: 1px solid black;
  cursor: pointer;
`;

const SocialIcons = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.a`
  margin-right: 15px;
  font-size: 24px;
  color: black;
  text-decoration: none;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: #555;
  }
`;

const FooterNewsletter = () => {
  return (
    <FooterContainer>
      <Wrapper>
        <LeftSection>
          <Title>10% OFF WHEN YOU SIGN UP</Title>
          <Text>
            Stay in the loop with the latest style news and get an exclusive 10%
            off when you subscribe to our emails. Learn more about our Privacy
            Policy here.{" "}
            <a href="#" style={{ textDecoration: "underline" }}>
              Terms and conditions
            </a>{" "}
            apply.
          </Text>
          <Form>
            <Input type="email" placeholder="your@address.com" />
            <Button type="submit">Submit</Button>
          </Form>
          <SocialIcons>
            <Icon href="#">
              <i className="fab fa-youtube"></i>{" "}
              {/* Replace with appropriate icons */}
            </Icon>
            <Icon href="#">
              <i className="fab fa-facebook-f"></i>{" "}
              {/* Replace with appropriate icons */}
            </Icon>
            <Icon href="#">
              <i className="fab fa-twitter"></i>{" "}
              {/* Replace with appropriate icons */}
            </Icon>
            <Icon href="#">
              <i className="fab fa-instagram"></i>{" "}
              {/* Replace with appropriate icons */}
            </Icon>
          </SocialIcons>
        </LeftSection>
        <RightSection>
          <Title>NEED HELP?</Title>
          <Text>
            For any enquiries please visit Omi{" "}
            <a href="#" style={{ textDecoration: "underline" }}>
              Customer Care
            </a>
            .
          </Text>
          <Title>CHANGE LOCATION</Title>
          <Text>
            <a href="#" style={{ textDecoration: "underline" }}>
              <img
                src="https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg"
                alt="United States"
                style={{
                  width: "20px",
                  marginRight: "8px",
                  verticalAlign: "middle",
                }}
              />
              United States
            </a>
          </Text>
        </RightSection>
      </Wrapper>
    </FooterContainer>
  );
};

export default FooterNewsletter;
