import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import ProductCarousel from "./product-carousel";

// Styled components
const Container = styled.div`
  height: 400px;
  display: flex;
  align-items: center;
  position: relative;
  max-width: 1500px;
  margin: 0 auto;
  justify-content: space-between;

  @media (max-width: 1500px) {
    padding: 0 50px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    padding: 0 20px;
  }
`;

const LeftDiv = styled.div`
  flex: 0 0 30%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    flex: none;
    width: 100%;
  }
`;

const RightDiv = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 768px) {
    width: 100%;
    padding-top: 20px;
    justify-content: center;
  }
`;

const Content = styled.div`
  text-align: left;

  @media (max-width: 768px) {
    padding-top: 80px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Title = styled.h2`
  font-size: 20px;
  font-weight: bold;
  display: block;
  margin-bottom: 25px;
`;

const BigNumber = styled.div`
  font-size: 78px;
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
`;

const Subtitle = styled.h3`
  font-size: 18px;
  display: block;
  margin-bottom: 25px;
  font-weight: normal;
`;

const ShopNowButton = styled.button`
  width: 250px;
  height: 40px;
  background-color: black;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 14px;
  display: block;
`;

const OfferSection = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  // Fetch data from Strapi
  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://strapi-production-7b58.up.railway.app/api/scroller-one?populate[clothing_items][populate]=images"
      );
      if (
        response.data &&
        response.data.data &&
        response.data.data.attributes
      ) {
        setData(response.data.data);
      } else {
        setError("Unexpected data structure in API response");
      }
    } catch (error) {
      console.error("Error fetching offer section data:", error);
      setError("Failed to fetch offer section data");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!data) {
    return <div>Loading...</div>;
  }

  const { title: title, subtitle: subtitle, count: count } = data.attributes;

  return (
    <Container>
      <LeftDiv>
        <Content>
          <Title>{title}</Title>
          {/* <BigNumber>{count}</BigNumber> */}
          <Subtitle>{subtitle}</Subtitle>
          <ShopNowButton>Shop now</ShopNowButton>
        </Content>
      </LeftDiv>
      <RightDiv>
        <ProductCarousel dataEndpoint="https://strapi-production-7b58.up.railway.app/api/scroller-one?populate[clothing_items][populate]=images" />
      </RightDiv>
    </Container>
  );
};

export default OfferSection;
