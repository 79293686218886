import React from "react";
import HeaderBar from "../components/header-bar";
import MenuBar from "../components/menu-bar";
import HeroImage from "../components/hero-image";
import OfferSection from "../components/offer-section";
import OfferSectionWomen from "../components/offer-section-women";
import TwoImagesLayout from "../components/two-images-layout";
import SmallImagesLayout from "../components/small-images-layout";
import Separator from "../components/separator";
import JournalEntry from "../components/journal-entry";
import SixColumnLayout from "../components/six-column-layout";
import ThreeColumnLayout from "../components/three-column-layout";
import FooterNewsletter from "../components/footer-newsletter";
import Footer from "../components/footer";

function HomePage() {
  return (
    <>
      <HeaderBar />
      <MenuBar />
      <HeroImage />
      <OfferSection />
      <Separator />
      <TwoImagesLayout />
      <Separator />
      <OfferSectionWomen />
      <Separator />
      {/* <SmallImagesLayout /> */}
      {/* <Separator /> */}
      <JournalEntry />
      <Separator />
      <SixColumnLayout />
      <Separator />
      <ThreeColumnLayout />
      <FooterNewsletter />
      <Footer />
    </>
  );
}

export default HomePage;
