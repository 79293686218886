import React from "react";
import omiAvatar from "../../assets/chat/omi_avatar.svg";
import meAvatar from "../../assets/chat/me_avatar.svg";
import { Avatar, MessageBox } from "react-chat-elements";
import styled from "styled-components";

import { createGlobalStyle } from "styled-components";

const MessageItem = ({ message }) => {
  const avatar = message.position === "right" ? omiAvatar : meAvatar; // Change as per your avatars

  return (
    <div style={{ display: "flex", alignItems: "start", margin: "10px 0" }}>
      {message.position === "left" && <Avatar src={avatar} />}
      <MessageBox
        position={message.position}
        type={message.type}
        text={message.text}
        date={message.date}
      />
      {message.position === "right" && <Avatar src={avatar} />}
    </div>
  );
};

export default MessageItem;
